<template>
  <v-chip v-if="item">
    <v-avatar left>
      <v-img
        v-if="item.picture && item.picture.url"
        contain
        height="40"
        width="40"
        alt="Profile picture"
        :src="item.picture.url" />
      <Avatar v-else :username="`${item.firstname} ${item.name}`" :size="40"/>
    </v-avatar>
    <span class="ml-1">{{ item.firstname }} {{ item.name }}</span>
  </v-chip>
  <span v-else>{{ $t('common.misc.NA') }}</span>
</template>

<script>
import Avatar from "vue-avatar";

export default {
  name: "FMReferent",

  props: {
    item: { type: Object, default: () => null }
  },

  components: {
    Avatar
  }
}
</script>

<style scoped>

</style>
